import {useContext, useEffect, useState} from 'react'
import moment from 'moment'
import {Header} from '../../componentsRef/Header'
import searchCatalog from '../../assets/img/searchCatalog.svg'
import settingCircle2 from '../../assets/img/background-catalog.svg'
import settingCircle1 from '../../assets/img/background-catalog2.svg'
import {CatalogTable} from '../../components/NewComponents/CatalogTable'
import {CadenaDropdown} from '../../components/NewComponents/CadenaDropdown'
import {FaseDropdown} from '../../components/NewComponents/FaseDropdown'

import {CatalogDate} from '../../components/NewComponents/CatalogDate'
import {NombreDropdown} from '../../components/NewComponents/NombreDropdown'
import {CatalogsContext} from '../../context/catalogs/CatalogsContext'
import {ChainsContext} from '../../context/chains/ChainsContex'
import {getAllPromotions} from '../../services/promotion.service'
import dataJSON from '../../services/promotionSearch.json';


export const CatalogSearch = () => {
	const {catalogsState} = useContext(CatalogsContext)

	const [catalogsFiltered, setCatalogFiltered] = useState([])
	const [allCatalogs, setAllCatalogs] = useState([])
	const [inpValueFilter, setInpValueFilter] = useState('')
	const [selectPhaseSelected, setSelectPhaseSelected] = useState('')
	const [chainSelected, setChainSelected] = useState('')
	const [listCombine, setlistCombine] = useState('')
	const [promotions, setPromotions] = useState(dataJSON)
	const [cadenas, setCadenas] = useState([])

	useEffect(() => {
		console.log('Promociones:::::::::::')
		console.log(promotions);
	}, [])

	// Obtener las promociones, las cadenas vienen en el localStorage

	useEffect(() => {
        // Verifica si el item 'user_data' existe en localStorage
        const userData = localStorage.getItem('user_data');
        if (userData) {
            // Parsea el JSON y extrae las cadenas
            const parsedData = JSON.parse(userData);
			console.log("Cadenas parsedData::::::::::")
			console.log(parsedData);
            if (parsedData.user_data.cadenas) {
                setCadenas(parsedData.user_data.cadenas);
            }
        }
    }, []); // El array vacío [] asegura que useEffect solo se ejecute una vez al montar el componente


	// Obtener todos los productos
	useEffect(() => {
		;(async () => {
			try {
				// setLoading(true)
				if(promotions){
					const {data} = await getAllPromotions()
					if (data) {
						setPromotions(data)
						console.log("Promotion DATA!!!::::: ");
						console.log(data);
						// setLoading(false)
					}
				}
				
			} catch (error) {
				// setPromotion(Promotion)
				console.log(error)
				// setLoading(false)
			} finally {
				// setLoading(false)
			}
		})()
	}, [promotions]);

	return (
		<div className='HomeNew  CatalogSearch'>
			<img src={settingCircle1} className='circle-1-setting' alt='' />
			<img src={settingCircle2} className='circle-2-setting' alt='' />

			<Header />

			<div className='inner-header-setting active'>
				<div>
					<img src={searchCatalog} alt='' />
					<p>Búsqueda de Promociones</p>
				</div>

				<input
					type="text"
					id="codProd"
					placeholder="Código Producto: Ej 1029"
					onFocus={(e) => {
						e.target.style.borderColor = '#3676F5'
					}}
					style={{width: '200px'}}
				/>
				
				<CatalogDate
					setlistCombine={setlistCombine}
					listCombine={listCombine}
				/>
				<select name="cadena" id="cadena">
					<option>test 1</option>
					<option>test 2</option>
				</select>
				{ /*
				<CadenaDropdown
					chains={cadenas}
					
					chainSelected={chainSelected}
					setChainSelected={setChainSelected}
				/>
				{/* <ProdDropdown /> */}
				<FaseDropdown  />
				
				<a 
					href='/Dashboard/planificacion'
					className='Limpiar'
				>
					Planificación Promocional
				</a>

			</div>

			<div className='search-table-area'>
				<h1>{`${promotions.length} Resultados`}</h1>
				<CatalogTable data={promotions} />
			</div>
		</div>
	)
}
