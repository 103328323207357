export const NewInputModule = ({type, id, placeholder}) => {
	return (
		<input
			type={type}
			id={id}
			placeholder={placeholder}
			onFocus={(e) => {
				e.target.style.borderColor = '#3676F5'
			}}
		/>
	)
}
// 3676F5
