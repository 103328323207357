import React from 'react'
import checkboxImage from '../../assets/img/Checkbox.svg'
import {numeroaDinero} from './helper'
export const RowProductSidebar = ({checkboxAvail = null, id, codigoProducto, 
descripcion, 
precio,
valueRow,
selected = false,
}) => {
	return (
		<div
			className={`RowProductSidebar body-checkboxes-sidebar-row ${
				!checkboxAvail && 'grey'
			}`}
		>
			<div className='first-row-sidebar-product'>
				{checkboxAvail ? (
					<>
						<input
							type='checkbox'
							id={`checkbox_${id}`}
							className='body-checkboxes-sidebar checkbox_select'
							onChange={(e) => {
								const checkbox = e.target.closest('.RowProductSidebar')
								console.log(e.target.checked)
								if (e.target.checked === true) {
									checkbox.classList.add('activecheckboxRow')
								} else {
									checkbox.classList.remove('activecheckboxRow')
								}
							}}
							value={valueRow}
						/>
						<label htmlFor={`checkbox_${id}`}>
							<svg
								width='11'
								height='8'
								viewBox='0 0 11 8'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M3.78918 8C3.68417 8 3.58499 7.98257 3.49165 7.94771C3.3983 7.91285 3.31079 7.85476 3.22912 7.77342L0.218775 4.7756C0.0670908 4.62455 -0.00571732 4.43561 0.00035004 4.2088C0.00595068 3.98245 0.0845929 3.79375 0.236277 3.6427C0.387961 3.49165 0.577682 3.41612 0.805442 3.41612C1.03273 3.41612 1.21639 3.49165 1.3564 3.6427L3.78918 6.06536L9.65235 0.226579C9.79236 0.0755264 9.97905 0 10.2124 0C10.4458 0 10.6383 0.0755264 10.79 0.226579C10.93 0.377632 11 0.566333 11 0.79268C11 1.01949 10.93 1.20842 10.79 1.35948L4.34924 7.77342C4.26757 7.85476 4.18006 7.91285 4.08671 7.94771C3.99337 7.98257 3.89419 8 3.78918 8Z'
									fill='white'
								/>
							</svg>
						</label>
					</>
				) : (
					<>
						<img src={checkboxImage} alt='' className='' />

						<input disabled
							type='checkbox'
							id={`checkbox_first_${id}`}
							style={{ display: 'none' }}
							className='body-checkboxes-sidebar'
							/*
							onChange={(e) => {
								const checkbox = e.target.closest('.RowProductSidebar')
								console.log(e.target.checked)
								if (e.target.checked === true) {
									checkbox.classList.add('activecheckboxRow')
								} else {
									checkbox.classList.remove('activecheckboxRow')
								}
							}}
							*/
						/>
						<label htmlFor={`checkbox_first_${id}`} style={{display: 'none'}}>
							<svg
								width='11'
								height='8'
								viewBox='0 0 11 8'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M3.78918 8C3.68417 8 3.58499 7.98257 3.49165 7.94771C3.3983 7.91285 3.31079 7.85476 3.22912 7.77342L0.218775 4.7756C0.0670908 4.62455 -0.00571732 4.43561 0.00035004 4.2088C0.00595068 3.98245 0.0845929 3.79375 0.236277 3.6427C0.387961 3.49165 0.577682 3.41612 0.805442 3.41612C1.03273 3.41612 1.21639 3.49165 1.3564 3.6427L3.78918 6.06536L9.65235 0.226579C9.79236 0.0755264 9.97905 0 10.2124 0C10.4458 0 10.6383 0.0755264 10.79 0.226579C10.93 0.377632 11 0.566333 11 0.79268C11 1.01949 10.93 1.20842 10.79 1.35948L4.34924 7.77342C4.26757 7.85476 4.18006 7.91285 4.08671 7.94771C3.99337 7.98257 3.89419 8 3.78918 8Z'
									fill='white'
								/>
							</svg>
						</label>
					</>
				)}
			</div>
			<div className='second-row-sidebar-product'>
				<a disabled>{codigoProducto}</a>
			</div>
			<div className='third-row-sidebar-product'>
				<p>{descripcion}</p>
			</div>
			<div className='fourth-row-sidebar-product'>
				<p>{numeroaDinero(precio)}</p>
			</div>
			{/*
			<div className='fifth-row-sidebar-product'>
				<p>Label</p>
			</div>
						*/}
		</div>
	)
}
