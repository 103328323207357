import React, {useState} from 'react'
import tickGreen from '../../assets/img/tick-green-box.svg'
import refresh from '../../assets/img/refresh.svg'
import roundArrow from '../../assets/img/round-arrow.svg'
import loaderImg from '../../assets/img/loader_img.png'
import arrowGray from '../../assets/img/arrow-gray.svg'
import {movePhase, getValidationPromo} from '../../services/movePhase.service'

export const MoverProductSidebar = ({movimientos, promotion, setAccionGuardar, 
	setafterState, setSvg, aprobarMovimiento, textoValidacion, validacion, actionNormalMenu,
	simulacionPage, setSimulacionPage
}) => {
	const [steps, setSteps] = useState(1)
	const [fase, setFase] = useState('')
	const [idFase, setIdFase] = useState('')
	const [states, setStates] = useState(null)
	const [validation, setValidation] = useState(null)
	const [errores, setErrores] = useState(null)

	const clicEditar = () => {
		// Obtén una referencia al elemento por su ID
		const targetElement = document.getElementById('botonMover');

		// Crea un nuevo evento de clic
		const clickEvent = new Event('click', {
		bubbles: true, // Para que el evento se propague a través de la jerarquía de elementos
		cancelable: true, // Para que el evento sea cancelable
		});

		// Dispara el evento en el elemento objetivo
		targetElement.dispatchEvent(clickEvent);
	}

	const validar = () => {
		(async () => {
			try {
				// alert("¡Validando!");
				const val = await getValidationPromo(promotion.data_promocion.id, promotion.data_promocion.fase_promo);
				console.log("¡Validación de Promoción!");
				console.log(val.data);
				setValidation(val.data);
	
				if (val.data.errores_totales === 0) {
					// alert("Aprobado y validado");
					movePhase(promotion.data_promocion.id, idFase);
					setSteps(4);
					setErrores(null);
					setTimeout(() => {
						window.location.pathname = '/HomeNew';
					}, 5000);
				} else {
					// alert("Hay errores");
					let errores = "";
					val.data.errores.forEach((error) => {
						errores += error + "\n";
					});
					// alert("La promoción no puede ser movida de fase debido a los siguientes errores: \n" + errores);
					setErrores(errores);
					setSteps(5);
				}
	
			} catch (error) {
				console.error("Error:", error);
			}
		})();
	};

	return (
		<div className='AddProductSdebar'>
			<div className='topbar'>
				<h1>Mover</h1>
			</div>

			<div className='form-wrapper-area MoverProductSidebar'>
				{steps === 1 && (
					<div className='first'>
					<h1>Selecciona que quieres hacer con el Catálogo:</h1>
					{movimientos.movimientos_posibles.map((movimiento) => {
						if (movimiento.estado_fase === 'Descartado') {
							return null; // Si es igual a 'Descartado', no se renderiza nada
						}
						let imgFinal = tickGreen;
						let boxClass = 'box green-box';
						if (movimiento.estado_fase === 'Solicita Cambios') {
							imgFinal = refresh;
							boxClass = 'box refresh-box';
						}
						return (
					  <div
						key={movimiento.id}
						className={boxClass}
						onClick={(e) => {
						  setAccionGuardar(true)
						  setFase(movimiento.siguiente_fase)
						  setIdFase(movimiento.id)
						  setSteps(2);
						}}
						
					  >
						<img src={imgFinal} alt='' style={{cursor: "pointer", marginLeft: "10px"}}/>
						<div className='presentation' style={{cursor: "pointer", marginLeft: "10px"}}>
						  <h1>{movimiento.estado_fase}</h1>
						  <p>
						  </p>
						</div>
					  </div>
						)
					})}
				  {/*
					<div
					  className='box refresh-box'
					  onClick={(e) => {
						setSteps(2);
					  }}
					>
					  <img src={refresh} alt='' />
					  <div className='presentation'>
						<h1>Solicitar Cambios</h1>
						<p>
						  Si Solicitas cambios el Catálogo volverá a fases anteriores
						  para que otros usuarios también puedan aplicar cambios.
						</p>
					  </div>
					</div>
					*/}
				  </div>
				  
				)}
				{steps === 2 && (
					<div className='second'>
						<h1>El Catálogo se moverá a:</h1>
						<p>Fase</p>
						<span className='badge' style={{width: "150px" , whiteSpace: "nowrap" }}>{fase}</span>
						<div className='form-area-inner'>
							{ /*
							<label htmlFor=''>Deja un Comentario (opcional)</label>
							<textarea
								placeholder='Lorem Ipsum Dolor'
								cols='30'
								rows='10'
							></textarea> */ }
							<div className='buttons-wrapper'>
								<button
									onClick={(e) => {
										setSteps(1)
									}}
								>
									Cancelar
								</button>
								<button
									onClick={(e) => {
										setSteps(3)
										// Primero se guarda a modo de respaldo.
										// setAccionGuardar(true)
										// Llamar a servicio Web con promotion.data_promocion.id y idFase
										// if(aprobarMovimiento){
											// if(fase === "Aprobado" ){
											if(fase === "Revisión" ){
												validar()
											}else{
												movePhase(promotion.data_promocion.id, idFase)
												setSteps(4);
												setErrores(null);
												setTimeout(() => {
													window.location.pathname = '/HomeNew';
												}, 5000);
											}
											
										// }
										
									}}
								>
									Confirmar
								</button>
							</div>
						</div>
					</div>
				)}

				{steps === 3 && (
					<div className='third'>
						<img src={roundArrow} alt='' />
						<h1>Moviendo Catálogo... La página se actualizará por completo en 
							los próximos segundos.
						</h1>
						<div className='LoaderNew customize'>
							<img src={loaderImg} alt='' />
							<svg
								width='25'
								height='30'
								viewBox='0 0 25 30'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<path
									d='M0 0V29.4H7.476V21.126H12.81C20.328 21.126 24.654 17.136 24.654 10.248C24.654 3.696 20.328 0 12.81 0H0ZM7.476 5.88H12.432C15.708 5.88 17.598 7.434 17.598 10.5C17.598 13.608 15.708 15.246 12.432 15.246H7.476V5.88Z'
									fill='#2E2E2E'
								/>
							</svg>
							<svg
								width='6'
								height='6'
								viewBox='0 0 6 6'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
							>
								<circle
									cx='3'
									cy='3'
									r='3'
									fill='url(#paint0_linear_459_158955)'
								/>
								<defs>
									<linearGradient
										id='paint0_linear_459_158955'
										x1='0.243243'
										y1='1.16798e-06'
										x2='7.34243'
										y2='0.446138'
										gradientUnits='userSpaceOnUse'
									>
										<stop stopColor='#FFAC23' />
										<stop offset='1' stopColor='#FF5334' />
									</linearGradient>
								</defs>
							</svg>
						</div>
						{ // <button>Cancelar</button>
							}
					</div>
				)}
				{steps === 4 && (
					<div className='third fourth'>
						<img src={roundArrow} alt='' />
						<h1>Catálogo Movido</h1>
						<p className='warning'>
							El Catálogo ha sido movido a la Fase: {fase}. 
							
							<br></br> La página se refrescará en los próximos segundos.
						</p>
						<div className='buttons-wrapper'>
							{ /* 
							<button>Seguir Editando</button>
							<button
								onClick={(e) => {
									setSteps(5)
								}}
							>
								Ir al Inicio
							</button>
							*/ }
						</div>
					</div>
				)}
				{steps === 5 && (
					<div className='third fourth'>
						<img src={arrowGray} alt='' />
						<h1>
							Existen errores en los formularios que impiden <br /> Mover este Catálogo
						</h1>
						<p className='warning'>
							Recomendamos revisar el siguiente mensaje:
							{textoValidacion.length > 0 ?
								<><br></br><br></br> 
								<span>Atención a los siguientes errores pendientes:</span> </>
								: <><br></br><br></br> 
								<span>Existen errores, revisar fechas y descuentos negativos.<br></br></span> </> 
							}
							
							{textoValidacion.length > 0 && 
								
									textoValidacion.map((item) => {
											return (
												<>{item} <br></br></>
											)
									}
							)}

							{ errores && errores }
						</p>
						<div className='buttons-wrapper'>
							<button onClick={(e) => {
								// setafterState(false)
								// clicEditar()
								actionNormalMenu()
								if(simulacionPage){
									setSimulacionPage(false)
								}
							}}>Seguir Editando</button>
							<button
								onClick={(e) => {
									window.location.pathname = '/HomeNew';
								}}
							>Ir al Inicio</button>
						</div>
					</div>
				)}
			</div>

			<div className='bottom-area'>
				<button className='button-cancel'
				onClick={(e) => {
					// clicEditar()
					actionNormalMenu()
					// setcurrentOption(null)
				}}
				>Cerrar</button>
			</div>
		</div>
	)
}
