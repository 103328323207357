import {useContext, useEffect, useState} from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Lider from '../../assets/img/lider.svg'
import Paper from '@mui/material/Paper'
import {Link} from 'react-router-dom'

export const CatalogTable = ({data}) => {
	const [Height, setHeight] = useState(0)
	const HandleResizeWork = () => {
		const WindowHeight = window.innerHeight - 320
		setHeight(WindowHeight)
	}
	useEffect(() => {
		window.addEventListener('resize', HandleResizeWork)
		HandleResizeWork()
	}, [])

	return (
		<TableContainer component={Paper} style={{height: Height}}>
			<Table sx={{minWidth: 650}} aria-label='simple table' stickyHeader>
				<TableHead>
					<TableRow>
					<TableCell width={100}>Cód. Producto</TableCell>
                        <TableCell>Cadena</TableCell>
                        <TableCell>Activación</TableCell>
                        <TableCell>Descripción</TableCell>
                        <TableCell>Descuento</TableCell>
                        <TableCell>Duración Sell-In</TableCell>
                        <TableCell>Duración Sell-Out</TableCell>
                        <TableCell>Exhibición</TableCell>
                        <TableCell>Fecha Actualización</TableCell>
                        <TableCell>Fecha Ingreso</TableCell>
                        <TableCell>ID Promoción</TableCell>
                        <TableCell>Inicio Sell-In</TableCell>
                        <TableCell>Término Sell-In</TableCell>
                        <TableCell>Inicio Sell-Out</TableCell>
                        <TableCell>Término Sell-Out</TableCell>
                        <TableCell>Inversión Adicional</TableCell>
                        <TableCell>Mecánica</TableCell>
                        <TableCell>Nombre Promoción</TableCell>
                        <TableCell>Precio Lista</TableCell>
                        <TableCell>Precio Promoción</TableCell>
                        <TableCell>Promotora</TableCell>
                        <TableCell>Publicación</TableCell>
                        <TableCell>PVP</TableCell>
                        <TableCell>Tipo Promoción</TableCell>
					</TableRow>
				</TableHead>
				<TableBody className='table-catalog-search'>
				{data.map((promo, index) => (
                        <TableRow key={index}>
                            <TableCell>{promo.codigo_producto}</TableCell>
                            <TableCell>{promo.nombre_cadena}</TableCell>
                            <TableCell>{promo.activacion}</TableCell>
                            <TableCell>{promo.descripcion}</TableCell>
                            <TableCell>{promo.descuento}</TableCell>
                            <TableCell>{promo.duracion_sellin}</TableCell>
                            <TableCell>{promo.duracion_sellout}</TableCell>
                            <TableCell>{promo.exhibicion}</TableCell>
                            <TableCell>{promo.fecha_actualizacion}</TableCell>
                            <TableCell>{promo.fecha_ingreso}</TableCell>
                            <TableCell>{promo.id_promocion}</TableCell>
                            <TableCell>{promo.inicio_sellin}</TableCell>
                            <TableCell>{promo.termino_sellin}</TableCell>
                            <TableCell>{promo.inicio_sellout}</TableCell>
                            <TableCell>{promo.termino_sellout}</TableCell>
                            <TableCell>{promo.inversion_adicional}</TableCell>
                            <TableCell>{promo.mecanica}</TableCell>
                            <TableCell>{promo.nombre_promocion}</TableCell>
                            <TableCell>{promo.precio_lista}</TableCell>
                            <TableCell>{promo.precio_promocion}</TableCell>
                            <TableCell>{promo.promotora}</TableCell>
                            <TableCell>{promo.publicacion}</TableCell>
                            <TableCell>{promo.pvp}</TableCell>
                            <TableCell>{promo.tipo_promocion}</TableCell>
                        </TableRow>
                    ))}
				</TableBody>
				{ /* <TableBody className='table-catalog-search'>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>

					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow>
					<TableRow sx={{'&:last-child td, &:last-child th': {border: 0}}}>
						<TableCell component='th' scope='row'>
							<a href='#'>Label</a>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<img src={Lider} alt='' />
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<div className='image-wrapper'>
								<p> Badge</p>
							</div>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>09/02/2023</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>15/02/2023 | 14:45</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>4.2%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>23.4%</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.684.692</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>$15.776.077</p>
						</TableCell>
						<TableCell component='th' scope='row'>
							<p>25</p>
						</TableCell>
					</TableRow> 
					</TableBody> */ }
			</Table>
		</TableContainer>
	)
}
