import PromoApi from '../utils/config/apiPromobooster'
import {envVariables} from '../utils/envVariables/envVariables'
import data from './promotionSearch.json';

export const getPromotion = async (id, estado) => {
	const token = localStorage.getItem('token')
	// return PromoApi.get(`${envVariables.baseUrl}/promotion/init/`+id, {
	return PromoApi.get(`${envVariables.baseUrl}/promotion/init/v2/`+id+`/`+estado, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
	}).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		return response
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  // window.location.href = '/';
		}
		
	  });
}

export const getAllPromotions = async () => {
	const token = localStorage.getItem('token')
	// return PromoApi.get(`${envVariables.baseUrl}/promotion/init/`+id, {
	return PromoApi.post(`${envVariables.baseUrl}/promotion/promotions`, {
		headers: {
			Authorization: `Bearer ${token}`,
		},
		body: {
			"nombre_cadenas": ["Jumbo", "Acuenta"],
			"codigo_productos": [1029],
			"fecha_inicio": "2024-01-01",
			"fecha_termino": "2024-04-30",
			"tipo_promocion": "sellin",
			"tipo_filtro_fechas": "sellout",
			"id_promociones": [1,2,3,4]
		  }
	}).then((response) => {
		// Manejar la respuesta exitosa aquí
		// console.log('Respuesta exitosa:', response.data);
		console.log("conexion exitosa 1");
		if(response){
			return response
		}
		return data
	})
	  .catch((error) => {
		// const {setAuthState} = useContext(AuthContext)
		// setAuthState(null)
		if (error.response && error.response.status === 401) {
		  // Manejar el error 401 (No Autorizado) aquí
		  console.error('Error 401: No Autorizado', error);
		  // window.location.href = '/';
		  // Puedes redirigir al usuario a la página de inicio de sesión u otras acciones relacionadas con la autenticación aquí.
		} else {
		  // Manejar otros errores de solicitud aquí
		  console.error('Error de solicitud:', error);
		  // window.location.href = '/';
		}

		return data;
		
	  });
}
